import Link from "next/link";
import Image from "next/image";

interface MdxLayoutInterface {
  children: React.ReactNode;
}

function MdxLayout({ children }: MdxLayoutInterface) {
  // Create any shared layout or styles here
  return (
    <div className="flex w-full flex-col items-center ">
      <div className="-ml-44 sm:-ml-[500px] md:-ml-[750px] lg:-ml-[1100px] mt-20 flex">
        <Link className="mt-2 px-3" href="/ai-optout-list">
          <Image
            src={require("public/images/aiOptOut/common/redact-back-arrow.svg")}
            alt={"redact-back-arrow"}
            className=""
            width={9}
            priority
          />
        </Link>
        <Link href={"/ai-optout-list"} className="font-semibold text-white text-xl">
          Back to optout list
        </Link>
      </div>
      <div className="flex flex-row">
        <div className="mt-10 h-[145px] w-3 bg-blue-500 sm:mt-7" />
        <div className="prose mt-5 prose-headings:mt-8 prose-headings:font-bold prose-a:text-white prose-h2:text-4xl prose-h3:text-3xl prose-h4:text-2xl prose-h5:text-xl prose-h6:text-lg prose-headings:text-white prose-strong:text-white text-white sm:prose-h1:w-[700px] sm:min-w-[1234px] sm:prose-h1:text-[45px] ">
          {children}
        </div>
      </div>
    </div>
  );
}

export default MdxLayout;
