import ClientPortal from "../general/clientPortal";
import { useCookieConsent } from "./cookieConsentProvider";

export const CookieWidget = () => {
  const { setShowBanner, loaded } = useCookieConsent();

  if (!loaded) {
    return null;
  }

  return (
    <ClientPortal>
      <div
        onClick={() => setShowBanner(true)}
        className="fixed bottom-[10px] left-[8px] z-50 flex h-[35px] w-[35px] items-center justify-center rounded-full hover:cursor-pointer"
      >
        <span className="icon-[mdi--cookie] h-[32px] w-[32px]"></span>
      </div>
    </ClientPortal>
  );
};
