import { CodeSnippet } from "../general/codeSnippet";
import { CookieInfo, StorageType } from "../../types";
import Switch from "../general/switch";
import { capitalize } from "../../util";
import { CookieType, useCookieConsent } from "./cookieConsentProvider";
import { useCallback } from "react";
import Image from "next/image";
import { cn } from "../pricing/PricingSection";

interface CookieTableProps {
  category: string;
  items: CookieInfo[];
}

const typeToIconProps: Record<StorageType, { title: string; icon: string }> = {
  cookie: {
    title: "Cookie",
    icon: "icon-[mdi--cookie]",
  },
  ls: {
    title: "LocalStorage",
    icon: "icon-[mdi--content-save]",
  },
  indexeddb: {
    title: "IndexedDB",
    icon: "icon-[mdi--storage]",
  },
};

const cookieIcon = (type: StorageType) => {
  const { icon, title } = typeToIconProps[type];

  return <span title={title} className={`${icon} h-[24px] w-[24px]`} />;
};

export function CookieTable({ category, items }: CookieTableProps) {
  return (
    <div key={category} className="mb-8">
      <h3 className="mb-4 font-semibold text-xl">{category}</h3>
      <div className="w-full overflow-x-auto">
        <table className="w-full min-w-[600px] table-fixed overflow-hidden rounded-lg border-collaps bg-brand-gray800">
          <colgroup>
            <col style={{ width: "5%", minWidth: "60px" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "13%" }} />
            <col style={{ width: "47%" }} />
          </colgroup>
          <thead>
            <tr className="text-left [&>th]:p-5">
              <th>Type</th>
              <th>Domain</th>
              <th>Name</th>
              <th>Expiry (days)</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody className="text-left [&>tr:nth-of-type(odd)]:bg-brand-gray200">
            {items.map((item, index) => (
              <tr key={index} className="text-left hover:bg-brand-gray800 [&>td]:px-5 [&>td]:py-4">
                <td className="w-[5%] max-w-[5%]">
                  <div className="flex items-center justify-center">{cookieIcon(item.type)}</div>
                </td>
                <td className="w-[15%] max-w-[15%]">{item.domain}</td>
                <td className="w-[15%] max-w-[15%] p-3 font-mono text-sm">
                  <CodeSnippet code={item.name} />
                </td>
                <td className="w-[10%] max-w-[10%]">{item.expiry}</td>
                <td className="w-[50%] max-w-[50%]">{item.info || "?"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const CookieDeclaration = ({
  cookiesByCategory,
}: {
  cookiesByCategory: Record<string, CookieInfo[]>;
}) => {
  return Object.entries(cookiesByCategory).map(([category, cookies]) => (
    <div key={category}>
      <CookieTable category={category} items={cookies} />
    </div>
  ));
};

const cookieCategories = ["analytics", "marketing", "preferences"] satisfies CookieType[];

export const CatImage = ({
  imageClass,
  wrapperClass,
  width = 842 / 3.5,
  height = 881 / 3.5,
}: {
  imageClass?: string;
  wrapperClass?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <div className={cn("ml-[5rem] scale-x-[-1] p-5", wrapperClass)}>
      <Image
        alt="cat market"
        className={imageClass}
        // original height and width
        width={width}
        height={height}
        src="/images/cookies/cat_cookie.png"
      />
    </div>
  );
};

const CookieSwitch = ({
  cookieName,
  disabled,
  save,
  value,
}: {
  cookieName: string;
  disabled?: boolean;
  save?: (value: boolean) => void;
  value: boolean;
}) => {
  return (
    <div className="mr-auto mb-[2rem] flex w-[100%] justify-center justify-center [&:last-of-type]:m-0 [&>div]:w-[50%]">
      <div>
        <Switch
          value={value}
          disabled={disabled}
          defaultValue={false}
          id={`${cookieName}-toggle`}
          onToggle={save}
        />
      </div>
      <div className="font-semibold">{capitalize(cookieName)}</div>
    </div>
  );
};

const PreferencesPanel = () => {
  const { savePrefs, preferences } = useCookieConsent();

  const save = useCallback(
    (key: CookieType) => (value: boolean) => {
      savePrefs({ [key]: value });
    },
    [savePrefs],
  );

  return (
    <div className="my-auto flex flex-col items-center">
      <div className="flex flex-col items-center rounded-lg bg-brand-gray200 px-[3rem] py-[2rem]">
        <CookieSwitch value={true} disabled={true} cookieName="functional" />
        {cookieCategories.map((category) => {
          return (
            <CookieSwitch
              key={category}
              value={preferences[category]}
              save={save(category)}
              cookieName={category}
            />
          );
        })}
      </div>
    </div>
  );
};

export const CookiePreferences = () => {
  return (
    <div className="flex flex-col content-center items-center">
      <h1 className="mb-[3rem] text-lg3">Cookie preferences</h1>
      <div className="flex flex-row">
        <PreferencesPanel />
        <CatImage />
      </div>
    </div>
  );
};
