export const bannerData = {
  bannerText: (
    <p>
      Happy Valentine&apos;s Day! Get 25% Off Redact ❤️{" "}
      <span className="underline">with code CUTIE25.</span>
    </p>
  ),
  bannerTextMobile: (
    <p>
      Happy Valentine&apos;s Day! Get 25% Off Redact ❤️{" "}
      <span className="underline">with code CUTIE25.</span>
    </p>
  ),
  path: "/valentines", //if its Redact.dev just use slash, if its a complete url put it in this format "https://www.stripe.com/redact"
  isActive: true,
  showsExpiration: true,
  showsExpirationMobile: true,
  expirationDate: "2025-02-17T08:00:00Z", //YYYY-MM-DD T HH:MM:SS UTC
};
