import Link from "next/link";
import styled from "styled-components";
import { useTheme } from "components/services/themeContext";
import asyncComponent from "components/split/asyncComponent";
import Script from "next/script";
const Logo = asyncComponent(() => import("components/general/logo"));

const StyledWrapper = styled.div`
  border-top: 1px solid var(--raised-background-color);
  display: flex;
  justify-content: center;
  height: 220px;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    height: auto;
    padding: 2rem 0;
    font-size: 12px;
  }
  font-family: "Open Sans", sans-serif;
`;
const StyledContainer = styled.div`
  width: calc(100% - 6rem);
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Footer = () => {
  const copyrightString = `© ${new Date().getFullYear()} Redact - All rights reserved`;
  const { theme } = useTheme();
  return (
    <StyledWrapper>
      <StyledContainer>
        <div className="mt-16 flex flex-col">
          <div className="-mt-10 flex flex-col-reverse sm:flex-row">
            <div className="mt-10 ml-16 flex md:mt-1 md:ml-5">
              <Logo size="w-auto h-16 sm:h-[2.5rem]" />
            </div>
            <div className="-ml-32 grid grid-cols-2 sm:ml-0 sm:flex">
              <div className="flex-col">
                <p
                  className={`ml-24 flex text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}
                >
                  Company
                </p>
                <Link
                  href="https://redact.dev/blog"
                  style={{ textDecoration: "none" }}
                  className={`ml-24 flex text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Blog
                </Link>
                <Link
                  href="/contact"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Contact Us
                </Link>

                <Link
                  href="/ai-optout"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  AI Opt Out
                </Link>
              </div>
              <div className="flex-col">
                <p
                  className={`ml-24 flex text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}
                >
                  Media
                </p>
                <Link
                  href="/affiliates"
                  style={{ textDecoration: "none" }}
                  className={`ml-24 flex text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Affiliates
                </Link>
                <Link
                  href="/press-kit"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Press Kit
                </Link>
              </div>
              <div className="w-80 flex-col">
                <p
                  className={`ml-24 flex text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}
                >
                  Legal
                </p>
                <Link
                  href="/privacy"
                  style={{ textDecoration: "none" }}
                  className={`ml-24 flex text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="/terms"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Terms & Conditions
                </Link>
              </div>
              <div className="flex-col">
                <p
                  className={`ml-24 flex text-lg sm:ml-5 md:mt-1 md:ml-32 ${theme === "light" ? "text-black" : "text-white"} font-bold`}
                >
                  Socials
                </p>
                <Link
                  href="https://x.com/redactdev"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className={`ml-24 flex text-lg sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Twitter
                  {<span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>}
                </Link>
                <Link
                  href="https://www.reddit.com/r/redact/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Reddit
                  {<span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>}
                </Link>
                <Link
                  href="https://discord.com/invite/PbyTFBEhtV"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className={`mt-3 ml-24 flex text-lg sm:mt-4 sm:ml-5 md:ml-32 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65 transition ease-in-out hover:opacity-100`}
                >
                  Discord
                  {<span className="icon-[fluent--open-12-regular] mt-1 ml-1"></span>}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex">
            <p
              className={`mt-3 ml-5 text-base sm:mt-5 sm:ml-0 ${theme === "light" ? "text-slate-900" : "text-slate-100"} opacity-65`}
            >
              {copyrightString}
            </p>
          </div>
        </div>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Footer;
