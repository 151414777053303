import React from "react";
import Button from "../general/buttonv2";
import { Bold } from "../general/util";
import { useCookieConsent } from "./cookieConsentProvider";
import Link from "next/link";
import { CatImage } from "./cookieDeclaration";

export const CookieConsentBanner = () => {
  const { savePrefs, setShowBanner, showBanner, setShowModal, hasSetConsent } = useCookieConsent();

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <div
        className={`fixed bottom-[20px] z-50 z-[101] flex w-full flex-col items-center justify-between sm:flex-row md:bottom-[40px]`}
      >
        <div className="relative w-full sm:w-5/6 md:w-3/6 xl:w-2/6">
          <CatImage width={150} height={150} wrapperClass="absolute top-[-110px] right-[20px]" />
          <div className="relative m-auto m-auto flex w-[90%] flex-col rounded-lg border-brand-baby-blue border-t-[18px] bg-brand-slate p-[30px] pt-[20px] shadow-lg">
            {hasSetConsent ? (
              <div
                onClick={() => setShowBanner(false)}
                className="absolute top-[-30px] right-[15px] flex h-[40px] w-[40px] items-center justify-center rounded-lg border-[4px] border-brand-baby-blue bg-brand-slate hover:cursor-pointer"
              >
                <span className={`icon-[material-symbols--close] h-[26px] w-[26px] bg-white`} />
              </div>
            ) : null}
            <p className="mb-0 text-sm sm:mb-0 sm:text-base">
              We use <Link href="https://redact.dev/cookies">cookies</Link> to give you the best
              online experience. Strictly necessary cookies are <Bold>on</Bold> by default.
              Additional cookies are off by default. See our privacy policy{" "}
              <Link href="https://redact.dev/privacy">here</Link>
            </p>
            <div className="mt-3 flex w-full md:mb-0 [&_button]:block">
              <Button noSparkle={true} onClick={() => setShowModal(true)} $accent="#eee">
                Manage preferences
              </Button>
              <Button
                noSparkle={true}
                wrapperClass="ml-auto"
                buttonPrimary={true}
                onClick={() => savePrefs({ marketing: true, analytics: true, preferences: true })}
              >
                Accept all
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
